<template>
    <div class="max_div" >
        <div class="top_div">
            <div class="top_div_title">
                <div style="min-height:1px">   </div>
                <div>
                    <el-button type="success" :size="tabsize" @click="editInfo()" icon="el-icon-edit">编辑</el-button>
                </div>
            </div>
            <div class="title">
                {{rowData.title}}
            </div>
            <div class="top_info">
                <el-row>
                    <el-col :span="4">
                        <span class="span-info"> 作者：{{ rowData.fbname}}</span>
                    </el-col>
                    <el-col :span="4">
                        <span v-if="rowData.add2 ==='市辖区'" class="span-info">城市：{{ rowData.add1 }}</span> 
                        <span v-if="rowData.add2 !=='市辖区'" class="span-info">城市：{{ rowData.add2 }}</span> 
                    </el-col>
                    <el-col :span="6">
                        <span class="span-info">发布时间：{{ rowData.fbtime | timestampToTime }}</span> 
                    </el-col>
                    <el-col :span="8">
                        <span class="span-info">活动时间：{{  rowData.stime | timestampToTime}} --  {{ rowData.etime | timestampToTime }}</span> 
                    </el-col>
                </el-row>
            </div>
            <div class="img_div">
                <div class="title">
                    封面图
                </div>
                <div style="margin-top:.3vw">
                    <el-image style="width:200px;height:200px;margin-left: 10px;" v-if="vif" :src="rowData.imgpath" :fit="fit"></el-image>
                </div>
            </div>
            <div class="img_div" style="margin-bottom:2vw">
                <div class="title">
                    详情图
                </div>
                <div style="margin-top:.3vw;display: flex;flex-wrap: wrap;">
                   <div  v-for="(item,index) in rowData.imgss" :key="index" style="display:flex;margin-left:10px ;">
                        <el-image style="width:200px;height:200px;margin-left: 10px;" v-if="imgsVif" :src="item" :fit="fit"></el-image>
                   </div>

                </div>
            </div>
            <div class="tabel_top_div">
                <div class="title">
                    累计报名人数:{{ count }}人
                </div>

                <div class="input_div" >
                    <el-row>
                        <el-col :span="19">
                            <el-input v-model="inputData" placeholder="姓名" :size="tabsize" style="width:18%"></el-input>
                            <el-input v-model="inputData2" placeholder="手机号" :size="tabsize" style="width:18%;margin-left: .5vw;"></el-input>
                            <!-- <el-select v-model="selectData" placeholder="服务商" :size="tabsize" style="margin-left:.5vw">
                                <el-option
                                v-for="item in selList"
                                :key="item.id"
                                :label="item.cname"
                                :value="item.id">
                                </el-option>
                            </el-select> -->
                            <el-button type="success" :size="tabsize" style="margin-left:1%" icon="el-icon-search" @click="getTableData2">筛选</el-button>
                        </el-col>
                        <el-col :span="5" >
                            <div style="text-align: end;margin-bottom: 1vw;" >
                                <el-button type="primary" :size="tabsize" style="margin-left:1%" icon="el-icon-bottom" @click="reportUser">导出用户</el-button>
                            </div>
                        </el-col>
                    </el-row>
                    
                </div>
            </div>
        </div>
        <div class="center_div">
            <el-table :data="tableData" border stripe :size="tabsize" style="overflow:auto" >
                <el-table-column label="姓名" prop='code' align='center' width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.cname}}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="孩子姓名" prop='code' align='center' width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.kname}}</span>
                    </template>
                </el-table-column> -->

                <el-table-column label="孩子姓名" prop='code' align='center' width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.kname}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="手机号" prop='code' align='center' width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.phone }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="报名时间" prop='code' align='center' width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.ctime |timestampToTime}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="城市" prop='code' align='center' width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.add2 }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="服务商" prop='code' align='center' width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.code }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="SN" prop='code' align='center' width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sn }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="备注" prop='code' align='center'>
                    <template slot-scope="scope">
                        <span>{{ scope.row.content }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="状态" prop='code' align='center' width="80">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status | typeFilter(that)}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作" prop='code' align='center' width="120">
                    <template slot-scope="scope">
                        <div class="button_list_div">
                            <el-button :size="tabsize" icon="el-icon-search"  type="primary" @click="buttonClick(scope.row)">查看</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer_div">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"  layout="total, sizes, prev, pager, next, jumper"
                    :total="totalRow">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getBaseUrl } from '../../../requset/http'
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
    mounted(){
         this.partyId = this.$route.query.id
            this.$nextTick(()=>{
                this.getTableData()
                this.getTableData2()
                this.getSelList()
                this.getUserNum()
            })
    },
    data(){
        return {
            partyId:'',
            vif:true,
            imgsVif:true,
            rowData:{},
            loading:false,
            currentPage: 1, //默认显示第一页
            pageSize: 10, //默认每页显示10条
            totalNum: '', //总页数
            order: 'desc',
            sort: 'id',
            totalRow:0,
            fit:'fit',
            inputData:'',
            inputData2:'',
            selectData:'',
            tableData:[],
            selList:[],
            dictList:[
                {
                    value:'10',
                    label:'未审核'
                },
                {
                    value:'20',
                    label:'已通过'
                },
                {
                    value:'30',
                    label:'未通过'
                }
            ],
            that:this,
            count:''
        }
    },
    // 监听尺寸按钮变化 改变表格大小
    computed: {
        tabsize() {
            return this.$store.state.tabsize
        }
    },
    methods: {
        editInfo(){
            this.$router.push({
                path:'/partyDialog',
                query:{
                    id:this.partyId
                }
            })
        },
        reportUser(){
            if(this.tableData.length){
                window.open(getBaseUrl()+'/pc/activity/userExport?aid='+this.partyId)
            }else{
                this.$alertError('没有要导出的数据')
            }
        },
        getSelList(){
            this.$api.party.getSelList({
                // id:this.partyId
            }).then((res)=>{
                console.log(res);
                if(res.status == 1){
                  this.selList = res.obj
                }
            }).catch((error)=>{

            })
        },
        getTableData(){
            this.$api.party.getRowInfo({
                id:this.partyId
            }).then((res)=>{
                console.log(res);
                if(res.status == 1){
                    this.rowData = res.obj
                    this.rowData.imgss = res.obj.imgs.split(',')
                    console.log(this.rowData);
                }
            }).catch((error)=>{

            })
        },
        getTableData2(){
            this.$api.party.getUserList({
                'jPage.pageNum':this.currentPage,
                'jPage.pageSize':this.pageSize,
                'jPage.sort':'',
                'jPage.order':'',
                'phone':this.inputData2,
                'cname':this.inputData,
                // 'mid':this.selectData,
                'aid':this.partyId
            }).then((res)=>{
                console.log(res);
                if(res.status == 1){
                    this.tableData =  res.obj.list
                    this.totalRow = res.obj.totalRow
                    // this.count = res.obj.totalRow
                }
            }).catch((error)=>{

            })
        },
        getUserNum(){
            this.$api.party.getUserNum({'aid':this.partyId}).then((res)=>{
                console.log(res);
                if(res.status == 1){
                    this.count = res.obj
                }
            }).catch((error)=>{

            })
        },
        // 分页大小
        handleSizeChange(val) {
            this.loading = true
            this.pageSize = val
            this.currentPage = 1 // 选择分页大小后跳转第一页
            this.getTableData2()
        },
        // 第几页
        handleCurrentChange(val) {
            this.loading = true
            this.currentPage = val
            this.getTableData2()
        },
        buttonClick(row){
            console.log(row);
            if(row.kiid == undefined){
                this.$alertError('当前孩子信息未同步')
                return
            }

            this.$router.push({
                path: "/vipinfo",
                query:{
                    kiid:row.kiid
                }
            });
            
        }
    },
    filters:{
        typeFilter(val,that){
            for(let i=0;i<that.dictList.length;i++){
                if(that.dictList[i].value == val){
                    return that.dictList[i].label
                }
            }
        }
    }
}
</script>

<style scoped>
    .top_div_title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1vw;
    }
    .title{
        font-size: 2.5vh;
        margin-bottom: 1vw;
    }
    .top_info{
        margin-bottom: 1vw;
    }
    .span-info {
        font-family: SourceHanSansCN-Regular;
        font-size: 1.04vw;
        font-weight: normal;
        font-stretch: normal;
        color: #1e1e1e;
    }
</style>
